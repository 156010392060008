import React, { useState, useEffect } from 'react'
import { Link } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Autocomplete.module.styl'
import { navigate } from 'gatsby'

const AutoComplete = () => {
  const context = useApi(
    {
      searchs: [],
    },
    'page-search'
  )

  //Método para unir os arrays que estão dentro de searchs

  const newArray = context.searchs.map(({ items }) => items)

  const deliverables = []
  for (let i = 0; i < newArray.length; i++) {
    deliverables.push(...newArray[i])
  }

  const [inputSearch, setInputSearch] = useState('')
  const [filterSearch, setFilterSearch] = useState([])

  useEffect(() => {
    if (inputSearch === '') {
      setFilterSearch([])
    }
  }, [inputSearch])

  const handleFilter = (e) => {
    setInputSearch(e.target.value)

    const newFilter = deliverables.filter(({ name }) =>
      new RegExp('(' + e.target.value + ')', 'gi').test(name)
    )

    setFilterSearch(newFilter)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      navigate(`/busca/${inputSearch.replace(/ /g, '-')}`)
      setInputSearch('')
    }
  }

  return (
    <div className={st.core}>
      <input
        type="text"
        value={inputSearch}
        onChange={handleFilter}
        placeholder="Busque aqui"
        onKeyPress={(e) => handleKeyPress(e)}
      />

      <div className={filterSearch.length > 0 && st.active}>
        <ul>
          {filterSearch.map(({ name, slug }, index) => (
            <li key={index}>
              <Link
                href={`/busca/${slug || inputSearch}`}
                onClick={() => setInputSearch('')}
                dangerouslySetInnerHTML={{
                  __html: name
                    .split(' I,')[0]
                    .split(' I e II')[0]
                    .split(new RegExp('(' + inputSearch + ')', 'gi'))
                    .map((str) =>
                      str.toUpperCase() === inputSearch.toUpperCase()
                        ? str.bold()
                        : str
                    )
                    .join(''),
                }}
              ></Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AutoComplete
