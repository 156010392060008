import React, { createContext, useState, useEffect } from 'react'
import { Loading, Menu } from '~/components'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'

interface Context {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const context = createContext<Context>(null)

const Layout = ({ children, location }: PageProps) => {
  const [loading, setLoading] = useState(false)
  const [loadingActive, setLoadingActive] = useState(false)

  const [enterOpacity, setEnterOpacity] = useState(0.9999)
  useEffect(() => {
    setEnterOpacity(0)
  }, [])

  return (
    <>
      {loading && <Loading active={loadingActive} />}
      {location.pathname !== '/' &&
        location.pathname !== '/404/' &&
        location.pathname !== '/404' && <Menu location={location} />}
      <context.Provider
        value={{
          setLoading: (value: boolean) => {
            if (value) {
              setLoading(true)
              setLoadingActive(true)
            } else {
              setLoadingActive(false)
              setTimeout(() => setLoading(false), 500)
            }
          },
        }}
      >
        <TransitionProvider
          location={location}
          mode="immediate"
          enter={{
            opacity: enterOpacity,
          }}
          usual={{
            opacity: 1,
          }}
          leave={{
            opacity: 0,
            config: {
              duration: 200,
            },
          }}
        >
          <TransitionViews>{children}</TransitionViews>
        </TransitionProvider>
      </context.Provider>
    </>
  )
}

export default Layout
