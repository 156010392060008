import React from 'react'
import * as st from '~/assets/styl/Banner.module.styl'
import { useMediaQuery } from 'react-responsive'
import { Link } from '~/components'
import nl2br from 'react-nl2br'

interface Props {
  title: string
  subtitle?: string
  text?: string
  img?: string
  imgMobile: string
  ctaMore?: string
  ctaDeliver?: string
}

const Banner = ({
  title,
  subtitle,
  text,
  img,
  imgMobile,
  ctaMore,
  ctaDeliver,
}: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' })
  const noBreak = useMediaQuery({ query: '(max-width: 767px)' })
  return (
    <div
      className={st.core}
      style={{
        background: `url(${
          isMobile === true ? imgMobile : img
        }) top center no-repeat`,
      }}
    >
      <section className="container">
        {noBreak === false ? <h1>{nl2br(title)}</h1> : <h1>{title}</h1>}
        {noBreak === false ? <p>{subtitle}</p> : ''}
        <p>{text}</p>
        <Link href="/porque-a-unicred/">{ctaMore}</Link>
        <Link href="#proxima-secao">{ctaDeliver}</Link>
      </section>
      <Link href="#proxima-secao">Role para baixo</Link>
    </div>
  )
}

export default Banner
