import React from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/DefaultContainer.module.styl'
import cn from 'classnames'
import nl2br from 'react-nl2br'

interface Props {
  title?: string
  text: string
  slug: string
  deliverCta: string
}

const DefaultContainer = ({ title, text, deliverCta, slug }: Props) => {
  return (
    <section className={cn('container', st.core)} id="proxima-secao">
      <h2>{nl2br(title)}</h2>
      <p>{text}</p>
      <Link to={`/${slug}/principais-entregaveis`}>{deliverCta}</Link>
    </section>
  )
}

export default DefaultContainer
