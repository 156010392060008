import React, { ChangeEvent, ReactNode, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { serialize } from 'object-to-formdata'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'
import api from '~/siteApi'
import cn from 'classnames'

function maskNumber(value: string, mask: string) {
  for (const char of value) mask = mask.replace(/#/, char)
  return mask.replace(/\D+$/g, '')
}

function unmaskNumber(value: string) {
  return value.replace(/\D/g, '')
}

function maskPhone(value: string) {
  if (!value) return
  value = unmaskNumber(value)
  return maskNumber(
    value,
    value.length > 10 ? '(##) #####-####' : '(##) ####-####'
  )
}

function maskCpf(value: string) {
  if (!value) return
  value = unmaskNumber(value)
  return maskNumber(value, '###.###.###-##')
}

function maskCnpj(value: string) {
  if (!value) return
  value = unmaskNumber(value)
  return maskNumber(value, '##.###.###/####-##')
}

function maskCurrency(value: string) {
  if (!value) return
  value = unmaskNumber(value)
  while (value.length < 3) value = '0' + value
  return (
    value
      .slice(0, -2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      .replace(/^[0.]+(\d)/, '$1') +
    ',' +
    value.slice(-2)
  )
}

function maskDate(value: string) {
  if (!value) return
  value = unmaskNumber(value)
  return maskNumber(value, '##/##/####')
}

const Mask = (
  mask: string,
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>
) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = unmaskNumber(e.target.value)
    onChange(e)
  }
  if (mask === 'phone')
    return {
      value: maskPhone(value) || '',
      onChange: handleChange,
    }
  else if (mask === 'cpf')
    return {
      value: maskCpf(value) || '',
      onChange: handleChange,
    }
  else if (mask === 'cnpj')
    return {
      value: maskCnpj(value) || '',
      onChange: handleChange,
    }
  else if (mask === 'int')
    return {
      value: unmaskNumber(value) || '',
      onChange: handleChange,
    }
  else if (mask === 'currency')
    return {
      value: maskCurrency(value) || '',
      onChange,
    }
  else if (mask === 'date')
    return {
      value: maskDate(value) || '',
      onChange: handleChange,
    }
  return { value: value || '', onChange }
}

type Props = {
  inputs: Array<{
    name: string
    label: ReactNode
    placeholder?: string
    obs?: string
    type?: string
    mask?: 'phone' | 'date' | 'int' | 'currency' | 'cpf' | 'cnpj'
    required?: boolean
    className?: string
    options?: Array<{
      name: string
      value?: string
    }>
    file?: {
      accept: string
      button: (props: { onClick: React.MouseEventHandler }) => ReactNode
    }
  }>
  button: ReactNode
  loadingButton?: ReactNode
  idPrefix: string
  path: string
  hiddenInputs?: {
    [key: string]: string
  }
  className?: string
}

const Form = ({
  inputs,
  button,
  loadingButton = button,
  idPrefix,
  path,
  hiddenInputs,
  className,
}: Props) => {
  const [data, setData] = useState({})
  const [error, setError] = useState({})
  const onChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const copyData = { ...data }
    copyData[e.target.name] = e.target.value
    setData(copyData)
  }
  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const copyData = { ...data }
    copyData[e.target.name] = e.target.checked
    setData(copyData)
  }
  const onChangeFile = (
    e: ChangeEvent<HTMLInputElement>,
    name?: string,
    file?: File
  ) => {
    if (!file && e?.target.files.length === 0) return
    const copyData = { ...data }
    copyData[name || e.target.name] = file || e?.target.files?.[0]
    setData(copyData)
  }

  const [loading, setLoading] = useState(false)

  const [cookies] = useCookies()
  const recaptchaRef = useRef<ReCAPTCHA>()

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const submit = (recaptcha?: string) => {
      api(
        'post',
        path,
        serialize({
          ...data,
          ...hiddenInputs,
          recaptcha,
          utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
        })
      )
        .then(() => navigate('/sucesso/'))
        .catch((e) => {
          if (e.errors) setError(e.errors)
          else console.error(e)
          setLoading(false)
        })
    }
    if (recaptchaRef.current)
      recaptchaRef.current.executeAsync().then((recaptcha) => {
        submit(recaptcha)
        recaptchaRef.current?.reset()
      })
    else submit()
  }

  return (
    <form className={className} onSubmit={onSubmit}>
      {inputs.map(
        (
          {
            name,
            label,
            placeholder,
            obs,
            type,
            mask,
            required = true,
            className,
            options = [],
            file,
          },
          key
        ) => (
          <div className={cn('form-group', className)} key={key}>
            {type === 'textarea' ? (
              <>
                <label htmlFor={`${idPrefix}-${name}`}>{label}</label>
                <textarea
                  name={name}
                  placeholder={placeholder}
                  id={`${idPrefix}-${name}`}
                  value={data[name] || ''}
                  required={required}
                  onChange={onChange}
                ></textarea>
              </>
            ) : type === 'select' ? (
              <>
                <label htmlFor={`${idPrefix}-${name}`}>{label}</label>
                <select
                  name={name}
                  id={`${idPrefix}-${name}`}
                  value={data[name] || ''}
                  required={required}
                  onChange={onChange}
                >
                  <option disabled hidden value="">
                    {placeholder}
                  </option>
                  {options.map(({ name, value = name }, key) => (
                    <option key={key} value={value}>
                      {name}
                    </option>
                  ))}
                </select>
              </>
            ) : type === 'checkbox' ? (
              <div className={cn('checkbox', data[name] && 'checked')}>
                <input
                  type="checkbox"
                  name={name}
                  id={`${idPrefix}-${name}`}
                  value={data[name] || false}
                  required={required}
                  onChange={onChangeCheckbox}
                />
                <label htmlFor={`${idPrefix}-${name}`}>{label}</label>
              </div>
            ) : type === 'file' && file ? (
              <div className="file">
                <label htmlFor={`${idPrefix}-${name}`}>{label}</label>
                <input
                  placeholder={placeholder}
                  value={(data[name] as File)?.name || ''}
                  onDragOver={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onDrop={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (
                      e.dataTransfer.files &&
                      e.dataTransfer.files.length > 0 &&
                      file.accept.includes(e.dataTransfer.files[0].type)
                    )
                      onChangeFile(null, name, e.dataTransfer.files[0])
                  }}
                  disabled
                />
                {data[name] && (
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      onChangeFile(null, name)
                    }}
                    className="remove"
                  ></button>
                )}
                {file.button({
                  onClick: (e) => {
                    e.preventDefault()
                    document.getElementById(`${idPrefix}-${name}`).click()
                  },
                })}
                <input
                  type="file"
                  name={name}
                  id={`${idPrefix}-${name}`}
                  required={!data[name] && required}
                  onChange={onChangeFile}
                  accept={file.accept}
                />
              </div>
            ) : (
              <>
                <label htmlFor={`${idPrefix}-${name}`}>{label}</label>
                <input
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  id={`${idPrefix}-${name}`}
                  required={required}
                  {...Mask(mask, data[name], onChange)}
                />
              </>
            )}
            {obs && <p className="obs">{obs}</p>}
            {error[name] && <p className="obs error">{error[name]}</p>}
          </div>
        )
      )}
      {process.env.GATSBY_RECAPTCHA_KEY && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          size="invisible"
        />
      )}
      <div className="form-submit">{loading ? loadingButton : button}</div>
    </form>
  )
}

export default Form
