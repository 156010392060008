import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/Footer.module.styl'
import logoUnicred from '~/assets/svg/svg-logo.svg'
import logoUnidos from '~/assets/svg/svg-logo-unidos.svg'

interface Props {
  location: Location
}

const Footer = ({ location }: Props) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const newUrl = location.pathname.split('/')
    if (
      newUrl[1] === 'agencias-unicred' ||
      newUrl[1] === 'nucleo-estrategico-unicred' ||
      newUrl[1] === 'unicred-central-conexao' ||
      newUrl[1] === 'unicred-corretora-conexao'
    ) {
      setUrl(newUrl[1])
    } else {
      setUrl('agencias-unicred')
    }
  })

  return (
    <footer className={st.core}>
      <section className="container clear-fix">
        <h2>plano de cargos, carreira e remuneração</h2>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/porque-a-unicred/">Porquê a Unicred</Link>
          </li>
          <li>
            <Link to="/carreira/">Carreira</Link>
          </li>
          <li>
            <Link to="/competencias/">Competências Unicred</Link>
          </li>
          <li>
            <Link to={`/${url}/principais-entregaveis/`}>
              Principais entregáveis
            </Link>
          </li>
          <li>
            <Link to="/manual-de-remuneracao/">Manual de remuneração</Link>
          </li>
        </ul>
        <img src={logoUnicred} alt="Logo da Unicred" />
      </section>
      <a
        href="https://www.tiki.com.br/"
        title="Site da Tiki Web"
        target="_blank"
        rel="noreferrer"
      >
        Tiki
      </a>
    </footer>
  )
}

export default Footer
